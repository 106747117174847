import { useEffect } from 'react';
import useSWR from 'swr';

import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { Wishlist } from '@interfaces/Wishlist';
import { SWR_KEYS } from '@lib/constants';
import { log, logBreadcrumb } from '@lib/utils';

export function useWishlist() {
  const apiUrl = new URL('/api/wishlist/get-wishlist', 'https://a');

  const fetcher = async (url) => {
    const res = await fetch(apiUrl.pathname + apiUrl.search);
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const { error } = (await res.json()) as BulkAPIErrorResponse;
      throw new Error(
        error?.message ? error.message.join('. ') : 'Unable to fetch wishlist'
      );
    }

    const { data: wishlist } = await res.json();
    return wishlist;
  };

  // Get wishlist
  const { data, error, isLoading } = useSWR<Wishlist>(
    SWR_KEYS.wishlist,
    fetcher,
    {
      dedupingInterval: 500,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404 or 400 or 500.
        if (
          error.status === 404 ||
          error.status === 400 ||
          error.status === 500
        )
          return;

        // Only retry up to 3 times.
        if (retryCount >= 3) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );
  useEffect(
    () =>
      logBreadcrumb({
        category: 'useWishlist',
        message: `received new wishlist data from BigCommerce`,
        level: 'info',
        data: data,
      }),
    [data]
  );

  if (error) log(`/get-wishlist: ${error.message}`);

  return { data: data ?? null, isLoading };
}
