import React, { ReactElement, ReactNode, useReducer, useState } from 'react';

import { AddToCartStatus } from '@components/Button/AddToCart';

export interface UIContextInterface {
  displayCart: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  recentlyViewed: {
    recentlyViewedIsOpen: boolean;
    setRecentlyViewedIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    recentlyViewedUpdates: number;
    updatedRecentlyViewed: React.DispatchWithoutAction;
  };
  displayDarkMode: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  displayMobileMenu: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  displayMenu: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  addingToCart: [
    AddToCartStatus,
    React.Dispatch<React.SetStateAction<AddToCartStatus>>,
  ];
  menuBreadcrumbs: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  showUnifiedMenu: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  headerHeight: [
    number | undefined,
    React.Dispatch<React.SetStateAction<number | undefined>>,
  ];
  headerScrollClass: [string, React.Dispatch<React.SetStateAction<string>>];
}

// Create a recently viewed context
const UIContext = React.createContext<UIContextInterface>(
  {} as UIContextInterface
);

export function UIContextProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  // we need to stick state in here
  const [darkModeIsOn, setDarkModeIsOn] = useState(false);
  const [quickCartIsOpen, setQuickCartIsOpen] = useState(false);
  const [recentlyViewedIsOpen, setRecentlyViewedIsOpen] = useState(false);
  const [recentlyViewedUpdates, updatedRecentlyViewed] = useReducer(
    (state: number) => state + 1,
    0
  );
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [addingToCartStatus, setAddingToCartStatus] = useState(
    AddToCartStatus.None
  );
  const [menuBreadcrumbs, setMenuBreadcrumbs] = useState<string[]>([]);
  const [showUnifiedMenu, setShowUnifiedMenu] = useState<boolean>(true);
  const [headerHeight, setHeaderHeight] = useState<number | undefined>();
  const [headerScrollClass, setHeaderScrollClass] = useState<string>('show');

  return (
    <UIContext.Provider
      value={{
        displayCart: [quickCartIsOpen, setQuickCartIsOpen],
        recentlyViewed: {
          recentlyViewedIsOpen,
          setRecentlyViewedIsOpen,
          recentlyViewedUpdates,
          updatedRecentlyViewed,
        },
        displayDarkMode: [darkModeIsOn, setDarkModeIsOn],
        displayMobileMenu: [mobileMenuIsOpen, setMobileMenuIsOpen],
        displayMenu: [menuIsOpen, setMenuIsOpen],
        addingToCart: [addingToCartStatus, setAddingToCartStatus],
        menuBreadcrumbs: [menuBreadcrumbs, setMenuBreadcrumbs],
        showUnifiedMenu: [showUnifiedMenu, setShowUnifiedMenu],
        headerHeight: [headerHeight, setHeaderHeight],
        headerScrollClass: [headerScrollClass, setHeaderScrollClass],
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export { UIContext };
