import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { logBreadcrumb } from '@lib/utils';

export function useStockLevel(entityId: string): {
  inStock: boolean | undefined;
  stockLevels: Record<number, boolean> | undefined;
} {
  const [inStock, setInStock] = useState<boolean | undefined>();

  const fetcher = async (url) => {
    const res = await fetch(url);

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (res.status !== 200) {
      const {
        error: { message, code },
      } = (await res.json()) as BulkAPIErrorResponse;
      const error = new Error('Unable to fetch stock levels');
      error.message = message.join('. ');
      throw error;
    }

    const { data } = await res.json();
    return data;
  };

  // Get stock levels
  const { data: stockLevels, error } = useSWR<Record<number, boolean>>(
    `/api/stockLevel?id=${entityId}`,
    fetcher
  );

  useEffect(() => {
    logBreadcrumb({
      category: 'useStockLevel',
      message: `received new stock level data`,
      level: 'info',
      data: stockLevels,
    });
    if (stockLevels) {
      // If at least one SKU is in stock, then product in stock
      const res = Object.values(stockLevels).some((val) => val);
      setInStock(res);
    } else {
      setInStock(undefined);
    }
  }, [stockLevels]);

  return { inStock, stockLevels };
}
