/**
 * Display the flavour options for a product
 * On click of a flavour, should set the relevant variant
 */

import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { HighlightTag } from '@components/Typography/Tag/Tag';
import { useCustomer } from '@hooks/customer/useCustomer';
import { Variant } from '@interfaces/Product';
import { CUSTOMER_GROUPS } from '@lib/constants';
import { isVariantAvailable } from '@lib/productHelpers';

import { tagMap } from './RectangleOptions';

interface Props {
  instanceId: string;
  productSku: string;
  option: {
    displayName: string;
    entityId: number;
    isRequired: boolean;
    checkedByDefault?: boolean;
    displayStyle?: string;
    values?: {
      entityId: number;
      label: string;
      isDefault: boolean;
      hexColors?: string[];
      imageUrl?: string;
    }[];
  };
  selectedProductOptions: React.MutableRefObject<Record<number, number>>;
  changedOptions: number;
  setChangedOptions: React.DispatchWithoutAction;
  variants: Variant[];
  stock: Record<number, boolean> | undefined;
}

export function RectangleOptionsSimple({
  instanceId = '',
  productSku,
  option,
  selectedProductOptions,
  changedOptions,
  setChangedOptions,
  variants,
  stock,
}: Props): ReactElement {
  const { customer } = useCustomer();
  const [selectedOption, setSelectedOption] = useState(
    selectedProductOptions.current[option.entityId]
  );

  useEffect(() => {
    let mounted = true;
    if (mounted)
      setSelectedOption(selectedProductOptions.current[option.entityId]);

    return function cleanup() {
      mounted = false;
    };
  }, [selectedProductOptions, option.entityId, changedOptions]);

  const handleOptionChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(changeEvent.target.value));
    const addin = {};
    addin[option.entityId] = parseInt(changeEvent.target.value);
    selectedProductOptions.current = {
      ...selectedProductOptions.current,
      ...addin,
    };
    setChangedOptions();
  };

  /**
   * Check whether the option is in stock (or could be if other options not selected)
   * @param optionId the entityId of the option (e.g. for flavour WPI or whatever)
   * @param valueId the entityId of the value (e.g. for Chocolate)
   * @returns boolean
   */
  const checkInStock = (optionId: number, valueId: number) => {
    // all variants of this value
    // (e.g. we have selected 250g and want all 250g regardless of flavour)
    const possibleVariants = variants.filter((pv) => {
      if (!pv.options) return false;
      return (
        pv.options.filter(
          (o) => o.optionId === optionId && o.valueId === valueId
        ).length > 0
      );
    });

    // have I selected other limiting options? else just am I in stock for any option?
    /*
      1. check what we  have set in productOptions (already selected)
      2. filter out the thing we are looking at (e.g. flavour) because we are gonna change it
      3. only want variants where optionId of productOption key is there, regardless of valueId
    */
    const limitedVariants = [] as Variant[];
    Object.keys(selectedProductOptions.current).forEach((key) => {
      if (key !== option.entityId.toString()) {
        limitedVariants.push(
          ...possibleVariants.filter(
            (pv) =>
              pv.options.map((o) => o.optionId.toString()).includes(key) &&
              pv.options
                .map((o) => o.valueId)
                .includes(selectedProductOptions.current[key])
          )
        );
      }
    });

    if (limitedVariants.length === 0 && stock)
      return possibleVariants.reduce(
        (acc, cur) => acc || (stock && stock[cur.variantId]),
        false
      );

    // in stock if at least some variant of it in stock (e.g. 250g lemonade, but not 1kg is in stock lemonade)
    return limitedVariants.reduce(
      (acc, cur) => acc || (stock !== undefined && stock[cur.variantId]),
      false
    );
  };

  return (
    <>
      <h4 className="pt-3 text-base">{option.displayName}</h4>
      <section
        className="flex flex-wrap gap-2.5 outline-offset-2 outline-magenta focus-within:outline data-[focus='none']:focus-within:outline-none
        sm:gap-5"
        onClick={(e) => {
          const target = e.target as HTMLElement;
          /**
           * Arrow keys register as onClick, but have clientX and Y as 0 so we can differentiate
           * We need to not show focus outline when a user clicks (using mouse)
           * but want it when they use keyboard (tab and arrow nav)
           */
          if (target && e.clientX !== 0) {
            target.closest('section')?.setAttribute('data-focus', 'none');
          }
        }}
        onKeyDown={(e) => {
          const target = e.target as HTMLElement;
          // Using keyboard so show the outline
          if (target) {
            target.closest('section')?.removeAttribute('data-focus');
          }
        }}
        id={option.displayName === 'Flavour' ? 'flavour-options' : 'options'}
      >
        {/* Flavour need special render for icons */}
        {option.displayName === 'Flavour' &&
          variants.map((variant, i) => {
            // From sanity
            const variantOption = variant.options.filter(
              (o) => o.optionId === option.entityId
            )[0];

            // Should we be showing it?
            const available = isVariantAvailable(
              variant,
              customer ? customer.customerGroupId : CUSTOMER_GROUPS.ALL
            );

            if (!available) return null;

            // It's available, so check stock, add tags, and render
            const inStock = checkInStock(
              option.entityId,
              variantOption.valueId
            );
            const optionTag = variant.optionTags[0];

            //because results from Algolia is still using value
            const label = variantOption.label;

            return (
              <div
                key={`${option.displayName}-option${i}`}
                className="relative flex"
              >
                <input
                  type="radio"
                  className="peer absolute cursor-pointer opacity-0"
                  name={option.entityId.toString()}
                  value={variantOption.valueId.toString()}
                  id={instanceId + variantOption.valueId.toString()}
                  onChange={handleOptionChange}
                  disabled={!inStock}
                  checked={variantOption.valueId === selectedOption}
                />
                <label
                  htmlFor={instanceId + variantOption.valueId.toString()}
                  className="flex h-[46px] min-w-[100px] items-center justify-center gap-2.5
                border-2 border-solid border-[#eaeaea] px-2.5 
                hover:bg-orange hover:text-[var(--color,--white)] hover:shadow-[0_0_3px_theme(colors.orange.DEFAULT)]
                peer-checked:bg-orange peer-checked:text-[var(--color,--white)]
                peer-disabled:opacity-60 peer-disabled:hover:border-[#eaeaea] peer-disabled:hover:bg-[#ccc]"
                >
                  {label}
                </label>
                {inStock && optionTag && (
                  <HighlightTag bgColour={`var(--${optionTag})`}>
                    {tagMap[optionTag]}
                  </HighlightTag>
                )}
                {stock !== undefined && !inStock && (
                  <HighlightTag bgColour="var(--grey-dark)">
                    Out of Stock
                  </HighlightTag>
                )}
              </div>
            );
          })}

        {/* Things other than flavour (don't need the icons) */}
        {option.displayName !== 'Flavour' &&
          option.values?.map((valueOption, i) => {
            const inStock = checkInStock(option.entityId, valueOption.entityId);
            const matchedVariants = variants.filter((v) =>
              v.options.map((o) => o.valueId).includes(valueOption.entityId)
            );
            let optionTag = '';
            if (matchedVariants.length === 1) {
              // Only one variant with this option, so we can check stock
              const variant = matchedVariants[0];
              optionTag = variant.optionTags[0];
              const available = isVariantAvailable(
                variant,
                customer ? customer.customerGroupId : undefined
              );
              if (!available) return null;
            }

            return (
              <div
                key={`${option.displayName}-option${i}`}
                id={`${productSku}-${option.entityId}-${valueOption.entityId}`}
                className="relative flex"
              >
                <input
                  type="radio"
                  className="peer absolute cursor-pointer opacity-0"
                  name={option.displayName + option.entityId.toString()}
                  value={valueOption.entityId.toString()}
                  id={
                    instanceId +
                    option.displayName +
                    valueOption.entityId.toString()
                  }
                  onChange={handleOptionChange}
                  disabled={!inStock}
                  checked={valueOption.entityId === selectedOption}
                />
                <label
                  htmlFor={
                    instanceId +
                    option.displayName +
                    valueOption.entityId.toString()
                  }
                  className="flex h-[46px] min-w-[100px] items-center justify-center gap-2.5
                border-2 border-solid border-[#eaeaea] px-2.5 
                hover:bg-orange hover:text-[var(--color,--white)] hover:shadow-[0_0_3px_theme(colors.orange.DEFAULT)]
                peer-checked:bg-orange peer-checked:text-[var(--color,--white)]
                peer-disabled:opacity-60 peer-disabled:hover:border-[#eaeaea] peer-disabled:hover:bg-[#ccc]"
                >
                  {valueOption.label}
                </label>
                {inStock && optionTag && (
                  <HighlightTag bgColour={`var(--${optionTag})`}>
                    {tagMap[optionTag]}
                  </HighlightTag>
                )}
                {stock !== undefined && !inStock && (
                  <HighlightTag bgColour="var(--grey-dark)">
                    Out of Stock
                  </HighlightTag>
                )}
              </div>
            );
          })}
      </section>
    </>
  );
}
