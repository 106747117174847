import useSWR from 'swr';

import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { Customer } from '@interfaces/Customer';
import { SWR_KEYS } from '@lib/constants';
import { log, logBreadcrumb } from '@lib/utils';

const fetcher = async (
  input: RequestInfo,
  init: RequestInit,
  ...args: any[]
) => {
  try {
    const res = await fetch(input, init);
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const { error } = (await res.json()) as BulkAPIErrorResponse;
      throw new Error(
        error?.message ? error.message.join('. ') : 'Unable to fetch customer'
      );
    }

    const responseBody = await res.json();
    return responseBody?.data ?? null;
  } catch (e) {
    throw e;
  }
};

export function useCustomer(): {
  customer: Customer | null;
  isLoading: boolean;
} {
  const {
    data: customer,
    error,
    isLoading,
  } = useSWR<Customer | null>(SWR_KEYS.customer, fetcher, {
    dedupingInterval: 500,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Never retry on 400 or 404 or 500, or 401.
      if (
        error.status === 400 ||
        error.status === 401 ||
        error.status === 404 ||
        error.status === 500
      )
        return;

      // Only retry up to 3 times.
      if (retryCount >= 3) return;

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
  });

  if (error) {
    logBreadcrumb({
      category: 'hooks',
      message: `useCustomer request`,
      level: 'error',
    });
    log(`/customer/current: ${error.message}`);
  }

  return { customer: customer ?? null, isLoading };
}
