import { PortableTextTypeComponentProps } from '@portabletext/react';

import { CustomImage } from '@components/Image/Image';
import { SanityImage } from '@interfaces/Sanity';
import { calculateHeight } from '@lib/images';

export function ImageRenderer(
  props: PortableTextTypeComponentProps<SanityImage>
) {
  if (props.value.fullWidth) {
    const width = props.value.width ? Math.min(1200, props.value.width) : 1200;
    return (
      <CustomImage
        image={props.value}
        width={width}
        height={calculateHeight(width, props.value.ratio)}
      />
    );
  }

  const width = Math.min(300, props.value.width);
  return (
    <CustomImage
      image={props.value}
      width={width}
      height={calculateHeight(width, props.value.ratio)}
    />
  );
}
