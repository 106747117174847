import { PortableTextTypeComponentProps } from '@portabletext/react';

import { CustomImage } from '@components/Image/Image';
import { SanityImage } from '@interfaces/Sanity';

export function ImageSetRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    width: number;
    images: SanityImage[];
  }>
) {
  return (
    <div className="flex flex-wrap items-center justify-center gap-5">
      {props.value.images.map((image) => (
        <CustomImage
          image={image}
          width={props.value.width}
          height={props.value.width}
          key={image.asset._ref}
        />
      ))}
    </div>
  );
}
