import { ClientConfig, createClient } from '@sanity/client';

export const client = createClient({
  projectId: '263h0ltd',
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-05-01',
  useCdn: false,
} as ClientConfig);

export const cdnClient = createClient({
  projectId: '263h0ltd',
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-05-01',
  useCdn: true,
} as ClientConfig);
