import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';
import { twJoin } from 'tailwind-merge';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useAddItem } from '@hooks/cart/useAddItem';
import { logBreadcrumb } from '@lib/utils';

import { Button, ButtonStyle } from './Button';

export enum AddToCartStatus {
  None,
  Adding,
  Added,
  Failed,
}

interface Props {
  queryID?: string[] | string;
  disabled?: boolean;
  productId: number;
  variantId: number;
  quantity: number;
  optionSelections?: {
    option_id: number;
    option_value: number | string;
  }[];
  triggerCartModal?: boolean;
  successCallback?: () => void;
  className?: string;
}

export function AddToCart({
  queryID,
  disabled,
  productId,
  variantId,
  quantity = 1,
  optionSelections,
  triggerCartModal = true,
  className,
  successCallback,
}: Props): ReactElement {
  const addItem = useAddItem({
    include: ['line_items.physical_items.options'],
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>();
  const {
    displayCart: [quickCartIsOpen, setQuickCartIsOpen],
    addingToCart: [addingToCartStatus, setAddingToCartStatus],
  } = useContext<UIContextInterface>(UIContext);

  // Add the item
  const addToCart = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    logBreadcrumb({
      category: 'AddToCart',
      message: `Clicked add to cart button`,
      level: 'info',
      data: {
        productId,
        variantId,
        quantity,
        optionSelections,
      },
    });

    let cartResponse;
    try {
      setAddingToCartStatus(AddToCartStatus.Adding);
      if (triggerCartModal) {
        setQuickCartIsOpen(true);
      }
      const lineItemToAdd = {
        productId,
        variantId,
        quantity,
      };
      if (optionSelections && optionSelections.length > 0) {
        lineItemToAdd['optionSelections'] = optionSelections;
      }
      cartResponse = await addItem({
        lineItem: {
          productId,
          variantId,
          quantity,
        },
      });

      if (cartResponse.error !== null) {
        setError(true);
        if (cartResponse.error) {
          setErrorMessage(
            <Paragraph>
              We couldn't add that to your cart. {cartResponse.error}
            </Paragraph>
          );
        } else {
          throw new Error('Unexpected error');
        }
      }
      setAddingToCartStatus(AddToCartStatus.Added);
      if (successCallback) successCallback();
    } catch (error) {
      setAddingToCartStatus(AddToCartStatus.Failed);
      setError(true);
      setErrorMessage(
        <Paragraph>
          We couldn't add that to your cart. If you continue to have issues,
          please contact customer service.
        </Paragraph>
      );
    }
  };

  return (
    <>
      <ErrorAlert show={error} setShow={setError}>
        {errorMessage}
      </ErrorAlert>
      <form onSubmit={addToCart}>
        <input type="hidden" value={productId} name="productId" />
        <input type="hidden" value={variantId} name="variantId" />
        <input type="hidden" value={quantity} name="quantity" />
        <Button
          buttonStyle={ButtonStyle.primary}
          className={twJoin([className ?? '', 'gae-product-atc'])}
          type="submit"
          disabled={addingToCartStatus === AddToCartStatus.Adding || disabled}
          icon={<FaCartPlus />}
        >
          Add to cart
        </Button>
      </form>
    </>
  );
}
