import * as Dialog from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiCart } from 'react-icons/bi';
import { RxCross1 } from 'react-icons/rx';

import { AddToFavourites } from '@components/Button';
import { Button } from '@components/Button';
import { AddToCartStatus } from '@components/Button/AddToCart';
import { ButtonStyle, ButtonType } from '@components/Button/Button';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { FreeSamplesBanner } from '@components/FreeSamples';
import { CustomImage } from '@components/Image';
import { PurchaseLimits } from '@components/Product';
import { ProductInformation } from '@components/Product/PageSections/ProductInformation';
import { useStockLevel } from '@hooks/useStockLevel';
import { BaseProduct, Variant } from '@interfaces/Product';
import { SanityImage } from '@interfaces/Sanity';

function ImageGallerySection({
  images,
  freeSampleAvailable,
  name,
}: {
  images?: SanityImage[];
  freeSampleAvailable: boolean;
  name: string;
}) {
  if (!images) return <></>;
  return (
    <div className="hidden lg:block">
      {/* only need 1 main image, find the default image and falls back to first image if default not exist */}
      <CustomImage
        image={images.find((image) => image.defaultImage) || images[0]}
        width={500}
        height={500}
        className="w-full"
      />

      {freeSampleAvailable && <FreeSamplesBanner productName={name} />}
    </div>
  );
}

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  product: BaseProduct;
  isMobile: boolean;
}

export function QuickAddModal({
  show,
  setShow,
  product,
  isMobile,
}: Props): ReactElement {
  const router = useRouter();
  const { inStock, stockLevels } = useStockLevel(product.entityId.toString());

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
  });

  const watchOptionChanges = rhfMethods.watch();

  // Local State
  const [selectedVariant, setSelectedVariant] = useState<
    Variant | null | undefined
  >();

  // Refs
  const {
    addingToCart: [addingToCartStatus, setAddingToCartStatus],
  } = useContext<UIContextInterface>(UIContext);

  // When product changes, reset things
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setSelectedVariant(null);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [product]);

  // When a valid variant has been selected, set it and enable add to cart
  useEffect(() => {
    let mounted = true;

    const selectedOptions = rhfMethods.getValues();

    // when options changed, work out which variant that is
    const v = product.variants.filter((pv) =>
      pv.options.reduce(
        (acc, cur) => acc && selectedOptions[cur.optionId] == cur.valueId,
        true as boolean
      )
    );

    if (v.length === 1 && mounted) {
      setSelectedVariant(v[0]); // set the variant as selected (for adding to cart)
    } else {
      setSelectedVariant(null);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [product.variants, product.options, watchOptionChanges, rhfMethods]);

  // Set the default variant as selected
  useEffect(() => {
    const defaultVariant = product.variants.filter(
      (v) => v.variantId === product.defaultVariantId
    )[0];

    // Check if variant is in stock
    if (
      stockLevels &&
      defaultVariant &&
      stockLevels[defaultVariant.variantId]
    ) {
      // If in stock, set it as selected
      defaultVariant.options.forEach((option) => {
        rhfMethods.setValue(
          option.optionId.toString(),
          option.valueId.toString()
        );
      });
    } else if (stockLevels) {
      // If default not in stock, find the first in stock variant and set that as selected
      const firstInStock = product.variants.find(
        (v) =>
          stockLevels[v.variantId] && v.variantId !== product.defaultVariantId
      );
      if (firstInStock) {
        firstInStock.options.forEach((option) => {
          rhfMethods.setValue(
            option.optionId.toString(),
            option.valueId.toString()
          );
        });
      }
    }
  }, [
    stockLevels,
    product.defaultVariantId,
    product.variants,
    product,
    rhfMethods,
  ]);
  //#endregion

  // to hide AddToCartConfirmationStyle when dialog is closed
  useEffect(() => {
    if (!show) {
      setAddingToCartStatus(AddToCartStatus.None);
    }
  }, [setAddingToCartStatus, show]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setAddingToCartStatus(AddToCartStatus.None);
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const handleCartClicked = () => {
    setShow(false);
    router.push('/cart');
  };

  if (!product) return <></>;

  return (
    <Dialog.Root open={show} onOpenChange={setShow}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed left-0 top-0 z-3 flex h-full w-screen items-center justify-center bg-grey-transparent">
          <Dialog.Content
            aria-label="Overlay QuickAddModal content"
            className="dialog-content relative mx-auto my-[10vh] flex max-h-[90vh] w-[90vw] items-center justify-center bg-white p-[2rem] outline-none dark:border dark:border-solid dark:border-black dark:bg-black dark:shadow-[0px_10px_50px_hsla(0,0%,0%,0.33)] lg:w-[80vw] 2xl:w-[70vw]"
          >
            <div className="absolute top-0 z-3 flex w-full items-center justify-end bg-white p-4 pb-0 dark:bg-black">
              <div
                data-addedToCart={addingToCartStatus === AddToCartStatus.Added}
                className="absolute right-[80px] top-2.5 bg-green p-2 font-bold italic text-white opacity-0 data-[addedToCart=true]:animate-[addToCartConfirmation_5s_0.1s_forwards]"
              >
                <p className="m-0 text-xl font-bold italic">
                  Added to your cart
                </p>
              </div>
              <Button
                type={ButtonType.button}
                onClick={handleCartClicked}
                buttonStyle={ButtonStyle.tertiary}
                icon={<BiCart />}
              />
              <Dialog.Close asChild>
                <Button
                  type={ButtonType.button}
                  buttonStyle={ButtonStyle.tertiary}
                  icon={<RxCross1 />}
                  aria-label="Close"
                />
              </Dialog.Close>
            </div>

            <div className="max-h-[90vh] w-full overflow-auto pt-[5rem]">
              <div className="grid grid-cols-1 gap-6 pb-5 lg:grid-cols-2">
                {/* do not render product image on mobile mega menu */}
                {!isMobile && (
                  <ImageGallerySection
                    images={product.images}
                    freeSampleAvailable={false}
                    name={product.name || ''}
                  />
                )}
                <div
                  id="product-head-details"
                  data-testid="product-head"
                  data-product-id={product.entityId}
                >
                  <h2 className="text-center text-4xl font-bold italic tracking-tighter text-orange sm:text-left">
                    <AddToFavourites
                      sku={product.sku}
                      productId={product.entityId}
                    />
                    {product.name}
                  </h2>
                  {product.tgaStatement ? (
                    <p className="mt-0">{product.tgaStatement}</p>
                  ) : null}

                  <div>
                    <FormProvider {...rhfMethods}>
                      <ProductInformation
                        product={product}
                        inStock={inStock}
                        stockLevels={stockLevels}
                        selectedVariant={selectedVariant}
                        triggerCartModal={false}
                        productDetailsLink={product.slug}
                        rhfMethods={rhfMethods}
                      />
                    </FormProvider>

                    <PurchaseLimits
                      min={product.minPurchaseQty}
                      max={product.maxPurchaseQty}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
