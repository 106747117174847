import * as Dialog from '@radix-ui/react-dialog';
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { BiX } from 'react-icons/bi';

import { mergeStyles } from '@lib/styles';

export function Modal({
  children,
  show,
  setShow,
  className,
}: {
  children: ReactNode;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  className?: string;
}): ReactElement {
  return (
    <Dialog.Root open={show} onOpenChange={setShow}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-grey-transparent fixed left-0 top-0 z-3 grid h-full w-screen place-items-center overflow-y-auto">
          <Dialog.Content
            aria-label="Overlay dialog content"
            className={mergeStyles(
              className ?? '',
              'dialog-content relative mx-auto my-[10vh] flex w-[90vw] flex-col flex-nowrap items-center justify-center bg-white p-8 shadow-dialog outline-none tiny:w-[80vw] lg:w-[50vw] print:shadow-none'
            )}
          >
            <Dialog.Close asChild>
              <button
                type="button"
                aria-label="Close"
                className="absolute right-2.5 top-2.5 border-none bg-transparent font-secondary text-4xl text-black hover:cursor-pointer hover:text-orange hover:no-underline focus:cursor-pointer focus:no-underline dark:text-white dark:hover:cursor-pointer dark:hover:text-orange dark:hover:no-underline dark:focus:cursor-pointer dark:focus:no-underline print:hidden"
              >
                <BiX />
              </button>
            </Dialog.Close>
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export function ModalNoPortal({
  children,
  show,
  setShow,
  className,
}: {
  children: ReactNode;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  className?: string;
}): ReactElement {
  return (
    <div
      onClick={(e) => setShow(false)}
      data-open={show}
      aria-modal="true"
      className="modal bg-grey-transparent fixed left-0 top-0 z-3 grid h-full w-screen place-items-center overflow-y-auto"
    >
      <div
        aria-label="Overlay dialog content"
        role="dialog"
        onClick={(e) => e.stopPropagation()}
        className={mergeStyles(
          className ?? '',
          'dialog-content relative mx-auto my-[10vh] flex w-[90vw] flex-col flex-nowrap items-center justify-center bg-white p-8 shadow-dialog outline-none tiny:w-[80vw] lg:w-[50vw] print:shadow-none'
        )}
      >
        <button
          type="button"
          aria-label="Close"
          onClick={() => setShow(false)}
          className="absolute right-2.5 top-2.5 border-none bg-transparent font-secondary text-4xl text-black hover:cursor-pointer hover:text-orange hover:no-underline focus:cursor-pointer focus:no-underline dark:text-white dark:hover:cursor-pointer dark:hover:text-orange dark:hover:no-underline dark:focus:cursor-pointer dark:focus:no-underline print:hidden"
        >
          <BiX />
        </button>
        {children}
      </div>
    </div>
  );
}
