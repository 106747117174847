import Link from 'next/link';
import { ReactElement } from 'react';

export function FreeSamplesBanner({
  productName,
}: {
  productName?: string;
}): ReactElement {
  return (
    <div
      className="relative mb-8 ml-[90px] mr-5 mt-5 -skew-x-[8deg] border-none bg-orange pb-2 pt-2 text-xs transition-all
    delay-0 duration-200 ease-in-out hover:bg-grey-darkest"
    >
      <Link
        prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
        href="/free-samples"
        className="text-white no-underline"
      >
        <span
          className="absolute -left-[110px] -top-[5px] block h-[70px]
        w-[125px] skew-x-[8deg] bg-[url('/images/bn-free-sample-add-to-cart.webp')] text-center leading-5"
        />
        <span className="block skew-x-[8deg] px-2.5 py-0 text-center font-secondary text-2xl uppercase leading-5 tracking-wide">
          Free sample available!
        </span>
        {productName && (
          <span className="m-0 block skew-x-[8deg] p-0 text-center leading-5">
            Request a single sample of {productName}. One per person.
          </span>
        )}
      </Link>
    </div>
  );
}
