import Image from 'next/legacy/image';
import Link from 'next/link';
import { ReactElement } from 'react';

export function FreeSamplesBlock(): ReactElement {
  return (
    <div className="flex justify-center font-secondary">
      <Link
        prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
        href="/free-samples"
        className="text-white no-underline"
      >
        <Image
          src="/images/blog-post-images-freesample-622x250.jpg"
          alt="group of product images for proteins"
          width={622}
          height={250}
          className="block max-w-full"
        />
      </Link>
    </div>
  );
}
