/**
 * General tag component.
 */

import React, { ReactElement, ReactNode } from 'react';

export function Tag({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: (event) => void;
}): ReactElement {
  return (
    <button
      type="button"
      onClick={onClick}
      className="cursor-pointer bg-grey-light p-1 text-xs uppercase text-grey-darkest hover:bg-orange hover:text-white dark:bg-grey-dark dark:text-white dark:hover:bg-orange"
    >
      {children}
    </button>
  );
}

export function HighlightTag({
  bgColour,
  children,
}: {
  bgColour: string;
  children: ReactNode;
}) {
  return (
    <span
      style={
        {
          '--bgColour': bgColour,
          '--new': 'hsl(132, 68%, 73%)',
          '--newFlavour': 'hsl(132, 68%, 73%)',
          '--improvedFormula': 'hsl(132, 68%, 73%)',
          '--limitedEdition': 'var(--colour-primary)',
          '--limitedStock': 'var(--colour-primary)',
          '--sellingFast': 'var(--colour-primary)',
          '--vegan': 'var(--plant)',
          '--vegetarian': 'var(--plant)',
          '--hasta': 'hsl(187, 55%, 43%)',
          '--unsweetened': 'var(--plant)',
        } as React.CSSProperties
      }
      className="absolute -right-3 -top-2.5 rotate-10 border border-solid 
      border-white bg-[--bgColour] px-2 py-[1px] 
      text-[0.75rem] text-white shadow-[1px_1px_2px_var(--bgColour)]"
    >
      {children}
    </span>
  );
}
