import { ReactElement, useState } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';

import { copyLink } from '@lib/interaction';

/**
 * Button to access the users clipboard and write copyText to the clipboard when clicked. Copy logic is
 * complicated due to the jagged status of the clipboard API. Include error message if writing to the user's
 * clipboard is not possible.
 *
 * @param copyText - the text to be copied to the clipboard
 * @returns CopyButton
 */
export function CopyButton({ copyText }: { copyText: string }): ReactElement {
  const [isCopied, setIsCopied] = useState(false);
  const [failCopy, setFailCopy] = useState(false);

  const onCopied = () => {
    // Show copied message if clipboard returns successfully
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  };

  const onFailed = () => {
    // Show copy fail message if clipboard writing fails, i.e. permissions blocked
    setFailCopy(true);
    setTimeout(() => setFailCopy(false), 2000);
  };

  return (
    <button
      onClick={() => copyLink(copyText, onCopied, onFailed)}
      className="ga-social-share h-full bg-inherit text-inherit w-[300px]
      border border-solid border-grey-mid p-2 flex items-center"
      data-social-id="copy"
      aria-label="Copy page link to clipboard"
    >
        {/* If failure to copy, overwrite and show cant copy message */}
        {failCopy ? (
          <div className="grid h-full place-items-center">
            <p className="m-0 p-0 text-xs">No copy access</p>
          </div>
        ) : isCopied ? (
          <span
            className="grid h-full -translate-y-1 animate-[copy-confirmation_forwards] place-items-center opacity-0 delay-200
          duration-100"
          >
            Copied!
          </span>
        ) : (
          <>
          <span className="animate-copy-indicator inline-block h-full duration-200 truncate">
            {copyText}
          </span>
          <span><RiFileCopy2Line /></span>
          </>
        )}
    </button>
  );
}
