import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { GiCheckMark } from 'react-icons/gi';

import { Button, ButtonType } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { Modal } from '@components/Modal/Modal';

export function SuccessAlert({
  title = 'Success',
  message,
  show,
  setShow,
  children,
}: {
  title?: string;
  message?: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}): ReactElement {
  const open = () => setShow(true);
  const close = () => setShow(false);

  return (
    <Modal show={show} setShow={setShow} className="dark:bg-grey-darkest">
      <section
        data-testid="success-alert"
        className="flex flex-col items-center justify-center"
      >
        <GiCheckMark size="80px" color="var(--colour-success)" />
        <h2>{title}</h2>
        <p className="flex items-center justify-evenly gap-4">
          {children ? children : message}
        </p>
        <Button
          type={ButtonType.button}
          onClick={close}
          buttonStyle={ButtonStyle.cta}
        >
          Ok
        </Button>
      </section>
    </Modal>
  );
}
