import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { BsXCircle } from 'react-icons/bs';

import { Button, ButtonType } from '@components/Button';
import { Modal } from '@components/Modal/Modal';

export function ErrorAlert({
  title = 'Error',
  show,
  setShow,
  children,
}: {
  title?: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}): ReactElement {
  const open = () => setShow(true);
  const close = () => setShow(false);

  return (
    <Modal show={show} setShow={setShow} className="dark:bg-grey-darkest">
      <section className="flex flex-col items-center justify-center">
        <BsXCircle size="80px" color="hsl(0, 90%, 70%)" />
        <h2>{title}</h2>
        <div className="flex items-center justify-evenly gap-4">{children}</div>
        <Button type={ButtonType.button} onClick={close}>
          Ok
        </Button>
      </section>
    </Modal>
  );
}
